iframe#webpack-dev-server-client-overlay{display:none!important}
.d-flex {
  display: flex;
}
.search__jobs .ant-table-cell {
  max-width: 300px;
}


// TAG
.input__tag--wrapper .react-tagsinput{
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 3px 8px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #b2bec3;
  border-radius: 7px;
  transition: all 0.3s;

  .react-tagsinput-tag {
    background-color: #f5f5f5;
    border-radius: 7px;
    color: #2d3436;
    display: inline-block;
    font-family: "Poppins", sans-serif;
    border: 0;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 5px;
    margin-inline-end: 8px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
    .react-tagsinput-remove {
      color: #949494;
      font-size: 14px;
      &:hover {
        color: #2d3436;
      }
    }
  }

  input {
    font-feature-settings: 'tnum';
    color: #2d3436;
    font-size: 14px;
    line-height: 1.5715;
    margin: 0;
    &:hover {
      border-color: #0063f7;
      border-right-width: 1px !important;
    }
  }
}

.legend__wrapper {
  display: flex;
  margin-bottom: 16px;
  .legend__title {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-right: 15px;
  }

  .color__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Poppins", sans-serif;
    margin-right: 8px;
    &:not(:last-child) {
      padding-right: 8px;
      border-right: 1px solid gray;
    }
    .color__box {
      width: 20px;
      height: 20px;
      border: 1px solid gray;
    }
    .green__color {
      background-color: #98FB98;
    }
    .blue__color {
      background-color: #6495ED;
    }
    .gray__color {
      background-color: #D3D3D3;
    }
  }
}
.justify-content-between {
  justify-content: space-between;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.text-center {
  text-align: center;
}
.link-item {
  color: #111314 !important;
}
.hp-inventory-body-row-item {
  width: -webkit-fill-available;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-tooltip-inner {
  background-color: #ffffff !important;
  color: #636e72 !important;
}
.customDropDown {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 15px;
  color: #2d3436;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #b2bec3;
  border-radius: 7px;
  transition: all 0.3s;
}
.customDropDown:focus-visible {
  outline: none !important;
}